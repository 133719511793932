const firebaseConfig = {
        apiKey: "AIzaSyCdygRziYDxPA8yKJCHm9ZhMVmW-kcqe64",
        authDomain: "dayuan-avl-test.firebaseapp.com",
        databaseURL: "https://dayuan-avl-test.firebaseio.com",
        projectId: "dayuan-avl-test",
        storageBucket: "dayuan-avl-test.appspot.com",
        messagingSenderId: "24173723335",
        appId: "1:24173723335:web:5a86107d3f9d5f177e48e2",
        measurementId: "G-MP8YFTW61S"
      };

export default firebaseConfig;